/* style card on admin dash topseller  */
.ant-card-type-inner .ant-card-body {
  padding: 0;
}
.ant-card-actions {
  padding: 0 !important;
  margin: 0px 24px;
}
.ant-card-actions li {
  margin-right: 0px;
  text-align: end !important;
  padding: 0 !important;
}

/* table header */
/* .ant-table-header {
  background-color: aqua !important;
  font-weight: bold;
} */

/* .ant-table-tbody > tr > td {
  border-bottom: solid 1px gray !important;
  padding-left: 10px !important;
  margin-left: 5px !important;
} */

.ant-pagination-item-active {
  color: darkgreen !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-item {
  color: white !important;
  background-color: darkgreen;
}

.custom-table-row {
  /* background-color: brown !important; */
  border-bottom: solid 3px rgb(11, 8, 8) !important;
}

/* customize product image on dashboard to be centered */
.ant-image-preview-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-image-preview-mask {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-table {
  border: solid 0px gray;
  padding: 5px;
  width: 100%;
  height: 100vh;
  overflow: auto;
  scrollbar-width: none;
}
.producttable {
  scrollbar-width: none;
}

.custom-table::-webkit-scrollbar {
  display: none; /* For Chrome, Safari and Opera */
}
