@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /* font-family: "Quicksand", system-ui; */
  font-family: "Arial", "sans-serif";
  color: "#616161";
  background-color: "#ffffff";
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 10px;
  background-color: #97909070;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background-color: #1d6f2b;
}

@layer components {
  .hoverEffect {
    @apply cursor-pointer duration-300;
  }
  .headerSedenavLi {
    @apply cursor-pointer text-gray-300 underline-offset-2 hover:text-white hover:underline;
  }
}

input[type="number"]:focus,
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
  box-shadow: 0px 0px 1.5px #1d6f2b;
  border-color: #06b623;
}

/* ============= NavLink active Start here ============== */
nav .active li {
  color: black;
  font-weight: 600;
  text-decoration: 1px underline black;
}

@media screen and (max-width: 665px) {
  nav .active li {
    color: white;
    font-weight: 600;
    text-decoration: 1px underline white;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
/* ============= NavLink active End here ================ */

.item-selected {
  border-color: #5d636f;
}

/* disable ant model footeer */
.ant-modal-footer {
  display: none;
}

/* @import "antd/dist/antd.css"; */

.ant-tabs-tab-active {
  /* background: #ffff; */
  color: #1d6f2b;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #1d6f2b;
  pointer-events: none;
}

/* change antd button model */
.ant-btn-default {
  font-weight: bold;
  background: #ffff;
  color: #1d6f2b;
  border: 1px solid #1d6f2b;
}
.ant-btn-default:hover {
  color: #1d6f2b;
  border: 1px solid #1d6f2b;
}
.ant-btn-primary:hover {
  background: #1d6f2b !important;
  color: white;
}
.ant-btn:focus {
  border: 1px solid #1d6f2b;
  color: #1d6f2b;
}
.ant-btn-primary:focus {
  border: 1px solid #1d6f2b;
  color: white;
  background-color: #1d6f2b !important;
}

.ant-btn-primary {
  font-weight: bold;
  color: white;
  background: #1d6f2b;
  border: none;
}
.ant-upload {
  border: none;
}
.ant-upload:hover {
  border: none;
}
/* modify input border */
.ant-input:hover {
  border: 1px solid #1d6f2b;
  box-shadow: #1d6f2b;
}
.ant-input:focus {
  border: 1px solid #1d6f2b;
}
/* disable antd footer Buttons */
.ant-modal-footer {
  display: none;
}
.ant-upload-list-item-name {
  display: none;
}
.ant-upload-span:nth-child(2) {
  display: none;
}
.ant-upload-list-item-name::after {
  border: 1px solid #1d6f2b;
}
.ant-upload-list-item-name {
  display: none !important;
}
.ant-upload-list-picture {
  display: flex !important;
  flex-wrap: wrap !important;
}

/* disable border on upload icon */
.ant-upload {
  border: none !important;
}

/* change inputs icons */
.ant-select-arrow {
  color: black;
}
.anticon-calendar {
  color: black;
}

/* hide first column on table product */
/* .ant-table-selection-column {
  background-color: black;
  display: none !important;
} */

.ant-layout-sider-trigger {
  background-color: #1d6f2b !important;
  color: white !important;
  display: none !important;
}
.capitalize-first::first-letter {
  text-transform: uppercase !important;
}

.ant-modal-mask {
  background-color: #000000 !important;
  opacity: 0.3 !important;
}

.ant-menu-title-content {
  color: #1d6f2b;
  font-weight: bold;
}
/* change select input look */
.ant-input {
  border: 1px solid #262626 !important;
  height: 38px !important;
  border-radius: 5px;
  width: 100%;
}
.ant-select {
  height: 45px !important;
  border-radius: 0px !important;
}
.ant-select-selector {
  border: 1px solid #262626 !important;
  height: 38px !important;
  border-radius: 5px;
}
.ant-select-selector:hover {
  border: 1px solid #1d6f2b !important;
}
.ant-select-selector:focus {
  border: 1px solid #1d6f2b !important;
}

.ant-input-number .ant-input-number-input {
  border: 1px solid #262626 !important;
  height: 38px !important;
  border-radius: 5px;
}

.stockinput {
  border: 1px solid #262626 !important;
  height: 38px !important;
  border-radius: 5px;
}
.stockinput:hover {
  border: 1px solid #1d6f2b !important;
}

/* .ant-select:hover {
  border: 1px solid #1d6f2b;
}
.ant-select-focused {
  border: 1px solid #1d6f2b;
} */

@media screen and (max-width: 665px) {
  .ant-space {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
  }
}

/* mobile money button */
.bg-gradient-custom {
  background: linear-gradient(to bottom, #ffd301, #ea1414);
}

.bg-gradient-custom-card {
  background: linear-gradient(to bottom, #a39d9d, #1a8ff4, #1172c8);
}

/* make product cards do not leave space  */

.my-masonry-grid {
  display: flex;
  margin-left: -10px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 10px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  background: white;
  margin-bottom: 10px; /* gutter size */
}

.bold_text {
  /* @apply  */
  font-weight: bold;
  font-size: 20px;
}

.bold_text1 {
  /* @apply  */
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 13px;
}

@media screen and (max-width: 665px) {
  .medium_text {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 20px;
    /* line-height: 45px; */
    height: 45px;
  }
  .medium2_text {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 15px;
  }
}

.medium_text {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 25px;
  /* line-height: 45px; */
  height: 45px;
}
.medium2_text {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.caregory_text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  /* line-height: 22.5px; */
  font-size: 15px;
}

.product-class-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.product-class {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
@media screen and (max-width: 665px) {
  .product-class {
    display: block;
  }
}

.product-class-name {
  font-weight: 600;
  font-size: 1.25rem;
}

.categories {
  display: flex;
  gap: 10px;
}

.category-name {
  font-size: 1rem;
  color: #555;
  white-space: nowrap !important;
}

.product-section {
  margin-top: 20px;
}

.product-display {
  /* padding: 20px; */
}

/* product details */

.zoom-container {
  position: relative;
  overflow: hidden;
}

.zoom-container img {
  transition: transform 0.3s ease-in-out;
}

.zoom-container:hover img {
  transform: scale(2); /* Adjust the scale value as needed */
}

/* hide scroll when scrolling  sidebar on shoppage */
.no-scroll {
  overflow: hidden;
}

/* 2fa */

.twofabg {
  background-image: url("./assets/images/2fa.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.PhoneInputInput {
  height: 100% !important;
  border: none !important;
  border: 0px solid grey !important;

  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  background-color: white !important;
  padding: 0.4em;
  display: flex;
  align-items: center;
}

.PhoneInputInput:focus {
  border: none !important;
  border: 0px solid grey !important;
}

/* footer */
.bottomfotter {
  background-image: url("./assets/images/footerimigongo.webp");
}
