.image-slider .slider-item {
    position: relative;
  }
  
.image-slider  .slider-item  img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
  }
  
.image-slider .slider-item  .slider-title {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
  }